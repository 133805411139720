import { TextField, Button, CardActions, CardContent, Card, CardHeader } from "@mui/material";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { newGarden, updateGarden } from "../../data/Garden";
import { GardenModel } from "../../data/Model";

interface AddGardenProps {
  garden: GardenModel;
  handleClose: () => void;
}

export default function ManageGarden({ garden, handleClose}: AddGardenProps): JSX.Element {
  const queryClient = useQueryClient();
  
  const [gardenName, setGardenName] = React.useState<string>(garden.name);


  const addGarden = useMutation({
    mutationFn: () => {
      return newGarden(gardenName);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden"] });
    },
  });

  const editGarden = useMutation({
    mutationFn: () => {
      return updateGarden(garden._id, gardenName);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden"] });
    },
  });

  const handleSaveGarden = () => {
    if (garden._id === "") {
      addGarden.mutate();
    } else {
      editGarden.mutate();
    }
    setGardenName("");
    handleClose();
  };

  const handleCancle= () => {
    setGardenName("");
    handleClose();
  }

  return (
    <Card>
      <CardHeader>New Garden Name</CardHeader>
      <CardContent>
        Enter the name of the new garden
        <TextField autoFocus margin="dense" label="Garden Name" fullWidth variant="standard" onChange={(e) => setGardenName(e.target.value)} value={gardenName}/>
      </CardContent>
      <CardActions>
        <Button onClick={handleCancle} variant="outlined">Cancel</Button>
        <Button onClick={() => handleSaveGarden()} variant="contained">Save</Button>
      </CardActions>
    </Card>
  );
}
