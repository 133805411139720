import { Alert, Button, IconButton, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import ScreenshotIcon from "@mui/icons-material/Screenshot";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "./VegiImage.module.scss";
import { Controller } from "react-hook-form";

interface VegiImageProps {
  name: string;
  value: string;
  control: any;
  setValue: any;
}

export default function VegiImage({ name, value, control, setValue }: VegiImageProps): JSX.Element {
  let streamStarted = false;

  const [playDisabled, setPlayDisabled] = React.useState<boolean>(false);
  const [pauseDisabled, setPauseDisabled] = React.useState<boolean>(true);
  const [screenshotDisabled, setScreenshotDisabled] = React.useState<boolean>(true);
  const [isRecording, setIsRecording] = React.useState<boolean>(false);
  const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [deviceId, setDeviceId] = React.useState<string>("");
  const [hasVideo, setHasVideo] = React.useState<boolean>(false);
  const [imageFileDataUrl, setFileDataUrl] = React.useState<string>(value); //"https://s3.ap-southeast-2.amazonaws.com/dev.frew.static/herbs-bundle-grey.svg");
  const [error, setError] = React.useState<string | null>(null);

  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const screenshotImage = useRef<HTMLImageElement>(null);
  const play = useRef(null);
  const pause = useRef(null);
  const screenshot = useRef(null);

  const constraints = {
    video: {
      // width: 1920,
      // height: 1080,
      facingMode: "environment",
      deviceId: {},
    },
  };

  useEffect(() => {
    getCameraSelection();
    setValue(name, imageFileDataUrl);
  }, [imageFileDataUrl, setValue, name]);

  const getCameraSelection = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const videoDevices = devices.filter((device) => device.kind === "videoinput");
          if (videoDevices.length === 0) {
            setHasVideo(false);
          } else {
            setHasVideo(true);
            setVideoDevices(videoDevices);
            const lastDeviceUsed = localStorage.getItem("deviceId");
            if (lastDeviceUsed) {
              setDeviceId(lastDeviceUsed);
            } else {
              setDeviceId(videoDevices[0].deviceId);
            }
          }
        });
      })
      .catch((error: any) => {
        console.log(error);
        setHasVideo(false);
      });
  };

  const handleOnPlay = () => {
    setIsRecording(true);
    if (streamStarted) {
      if (video.current !== null) {
        video.current.play();
      }
    } else if ("mediaDevices" in navigator) {
      const updatedConstraints = constraints;
      updatedConstraints.video.deviceId = { exact: deviceId };
      navigator.mediaDevices
        .getUserMedia(updatedConstraints)
        .then((stream) => {
          if (video.current !== null) {
            video.current.srcObject = stream;
            streamStarted = true;
            setPlayDisabled(true);
            setScreenshotDisabled(false);
            setPauseDisabled(false);
          } else {
            setError("Video element not found");
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setError("MediaDevices not supported");
    }
  };

  const handlePauseStream = () => {
    setIsRecording(true);
    if (video.current !== null) {
      video.current.pause();
      setPlayDisabled(false);
      setPauseDisabled(true);
    }
  };

  const handleScreenshot = () => {
    if (canvas.current !== null && video.current !== null ) {
      canvas.current.width = video.current.videoWidth;
      canvas.current.height = video.current.videoHeight;
      canvas.current.getContext("2d")?.drawImage(video.current, 0, 0);
      setFileDataUrl(canvas.current.toDataURL("image/png"));
      setIsRecording(false);
      setScreenshotDisabled(true);
      setPlayDisabled(false);
      setPauseDisabled(true);
      console.log("file URL", imageFileDataUrl.substring(0, 100));
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = e.target?.result;
        if (image) {
          setFileDataUrl(image.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const selectVideoDevice = (event: SelectChangeEvent) => {
    localStorage.setItem("deviceId", event.target.value as string);
    setDeviceId(event.target.value as string);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={styles.root}>
          <div>
            {isRecording ? (
              <video ref={video} autoPlay />
            ) : (
              <img src={imageFileDataUrl} alt="preview" ref={screenshotImage} />
            )}
          </div>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
          </Button> 
          {hasVideo && (
            <div> or take a photo
              <div>
                <Select onChange={selectVideoDevice} value={deviceId}>
                  {videoDevices.map((videoDevice) => {
                    return (
                      <MenuItem key={videoDevice.deviceId} value={videoDevice.deviceId}>
                        {videoDevice.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <IconButton ref={play} title="Play" onClick={handleOnPlay} disabled={playDisabled} color="error">
                  <PlayCircleIcon />
                </IconButton>
                <IconButton ref={pause} title="Pause" onClick={handlePauseStream} disabled={pauseDisabled}>
                  <PauseCircleIcon />
                </IconButton>
                <Button ref={screenshot} title="ScreenShot" onClick={handleScreenshot} disabled={screenshotDisabled}>
                  <ScreenshotIcon />
                </Button>
              </div>
            </div>
          )}
          <canvas ref={canvas} className={styles.hidden}></canvas>
          {error && <Alert severity="error">{error}</Alert>}
        </div>
      )}
    />
  );
}
