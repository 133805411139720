import { Chip, Paper, Typography } from "@mui/material";

interface TagsProps {
  value: string[];
}

export function Tags({ 
  value
}: TagsProps): JSX.Element {

  return (
      <Paper sx={{ display: "flex", justifyContent: "flex-start", alignContent: "flex-start", listStyle: "none", p: "2px" }}>
        <Typography variant="h6" sx={{ paddingRight: "10px" }}>
          Tags:
        </Typography>
        {value.map((tag) => {
            return <Chip key={tag} variant="outlined" label={tag} onDelete={undefined} color="primary" />
        })}
      </Paper>
  );
}
