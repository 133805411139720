import { Auth } from "aws-amplify";
import { PlantModel } from "./Model";
import axios from "axios";
import { baseApiUrl } from "./consts";

export const NewPlant = async (body: PlantModel) => {
  return axios.post(`${baseApiUrl}/plant`,body, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}

export const EditPlant = async (plantId: string, body: PlantModel) => {
  console.log("body", body)
  return axios.put(`${baseApiUrl}/plant/${plantId}`,body, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}
export const DeletePlant = async (plantId: string) => {
  return axios.delete(`${baseApiUrl}/plant/${plantId}`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}

export const GetPlants = async () => {
  return await axios
    .get(`${baseApiUrl}/plant`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    })
    .catch((error) => {
      console.log(error);
      return { data: { data: [] } };
    });
};
