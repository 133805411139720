import { Auth } from "aws-amplify";
import { PlotHistoryModel, PlotModel } from "./Model";
import axios from "axios";
import { baseApiUrl } from "./consts";

export const NewPlot = async (gardenId: string, body: PlotModel) => {
  return axios.post(`${baseApiUrl}/garden/${gardenId}/plot`,body, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}

export const EditPlot = async (gardenId: string, body: PlotModel) => {
  return axios.put(`${baseApiUrl}/garden/${gardenId}/plot/${body._id}`,body, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}
export const DeletePlot = async (gardenId: string, plotId: string) => {
  return axios.delete(`${baseApiUrl}/garden/${gardenId}/plot/${plotId}`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}

export const NewPlotHistory = async (gardenId: string, plotId: string, body: PlotHistoryModel) => {
  return axios.post(`${baseApiUrl}/garden/${gardenId}/plot/${plotId}/history`, body, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });
}