import { Auth } from "aws-amplify";
import axios from "axios";
import { GardenModel } from "./Model";
import { baseApiUrl } from "./consts";


export const getGardenData = async (gardenId: string) => {
  let url = `/garden`;
  if (gardenId !== "") {
    url = `/garden/${gardenId}`;
  }
  return await axios
    .get(`${baseApiUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    })
    .catch((error) => {
      console.log(error);
      return { data: { data: {} } };
    });
};

export const getAllGardenData = async () => {
  return await axios.get(`${baseApiUrl}/garden`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  });
};

export const newGarden = (gardenName: string) => {
  return Auth.currentSession()
    .then(session => {
      const token = session.getIdToken().getJwtToken()
      return axios.post<GardenModel>(
        `${baseApiUrl}/garden`,
        { name: gardenName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then( response => response.data)
    })
};

export const updateGarden = (gardenId:string, gardenName: string) => {
  return Auth.currentSession()
    .then(session => {
      const token = session.getIdToken().getJwtToken()
      return axios.put<GardenModel>(
        `${baseApiUrl}/garden/${gardenId}`,
        { 
          name: gardenName 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then( response => response.data)
    })
};


export const listGardens = async () => {
  const response = await axios
    .get(`${baseApiUrl}/garden`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    })
    .catch((error) => {
      console.log(error);
      return { data: {} };
    });

  return response;
};

export const deleteGarden = async (id:string) => {
  const response = await axios
    .delete(`${baseApiUrl}/garden/${id}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    })
    .catch((error) => {
      console.log(error);
      return { data: {} };
    });

  return response;
};
