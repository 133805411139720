import { Paper, Button, Accordion, AccordionSummary, AccordionActions, IconButton, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styles from "../../theme/GardenTheme.module.scss";
import ManagePlant from "../ManagePlant/ManagePlant";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeletePlant, GetPlants } from "../../data/Plants";
import { PlantModel } from "../../data/Model";
import { Tags } from "../Tags/Tags";

export default function Plants(): JSX.Element {
  const { data } = useQuery({ queryKey: ["plant"], queryFn: GetPlants });

  const [showAddPlant, setShowAddPlant] = React.useState<boolean>(true);
  const [editPlantId, setEditPlantId] = React.useState<string>("");
  const [expandedPlantId, setExpandedPlantId] = React.useState<string>("");
  const [isConfirmDelteOpen, setIsConfirmDeleteOpen] = React.useState<boolean>(false);
  const [plantIdToDetete, setPlantIdToDelete] = React.useState<string>("");

  const queryClient = useQueryClient();
  const deletePlant = useMutation({
    mutationFn: (plantId: string) => {
      return DeletePlant(plantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["plant"] });
    },
  });

  const handleAddPlantButton = () => {
    setShowAddPlant(false);
  };

  const handleCloseAddPlant = () => {
    setShowAddPlant(true);
  };

  const handlePlantChange = (plantId: string) => {
    if (expandedPlantId === plantId) {
      setExpandedPlantId("");
    } else {
      setExpandedPlantId(plantId);
    }
  };

  const handleEditPlant = (plantId: string) => {
    setEditPlantId(plantId);
    if (expandedPlantId !== plantId) {
      handlePlantChange(plantId);
    }
  };
  const handleCloseEditPlant = () => {
    setEditPlantId("");
  };

  const handleDeletePlant = (plantId: string) => {
    setPlantIdToDelete(plantId);
    setIsConfirmDeleteOpen(true);
  };
  const handleCancelDelete = () => {
    setIsConfirmDeleteOpen(false);
    setPlantIdToDelete("");
  };
  const handleConfirmDelete = () => {
    deletePlant.mutate(plantIdToDetete);
    setIsConfirmDeleteOpen(false);
    setPlantIdToDelete("");
  };

  return (
    <Paper elevation={3}>
      {showAddPlant ? (
        <Button onClick={handleAddPlantButton} variant="contained" className={styles.button}>
          Add Plant
        </Button>
      ) : (
        <ManagePlant handleClose={handleCloseAddPlant} plant={{} as PlantModel} />
      )}
      {data?.data?.map((plant: PlantModel) => {
        return (
          <Accordion key={plant._id} expanded={expandedPlantId === plant._id} onChange={() => handlePlantChange(plant._id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              {plant.name}
              <AccordionActions>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditPlant(plant._id);
                  }}
                >
                  <EditNoteIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeletePlant(plant._id);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </AccordionActions>
            </AccordionSummary>
            <AccordionDetails>
              {editPlantId === plant._id ? (
                <ManagePlant handleClose={handleCloseEditPlant} plant={plant} />
              ) : (
                <>
                  <p>{plant.description}</p>
                  {plant.daysToHarvest > 0 && <p>{plant.daysToHarvest} days to harvest</p>}
                  {plant.url && (
                    <p>
                      <Link href={plant.url} underline="always" target="_blank" rel="noopener">
                        Link
                      </Link>
                    </p>
                  )}
                  {plant.image && (
                    <p>
                      <img src={`https://assets.vegiweb.frew.dev/${plant.image}`} alt={plant.name} />
                    </p>
                  )}
                  <Tags value={plant.tags} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Dialog open={isConfirmDelteOpen} fullWidth={true} maxWidth="md">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>You can not undo this action</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant="outlined">Cancel</Button>
          <Button color="error" onClick={handleConfirmDelete} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
