import './App.css';
import MainPage from './container/MainPage'

function App() {
  
  return (
       <MainPage/>
  );
}

export default App;
