import Button from "@mui/material/Button";
import { Card, CardActions, CardContent, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PlantModel } from "../../data/Model";
import { EditPlant, NewPlant } from "../../data/Plants";
import VegiImage from "../VegiImage/VegiImage";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { EditTags } from "../Tags/EditTags";

interface ManagePlantProps {
  handleClose: () => void;
  plant: PlantModel;
}

export default function MangePlant({ handleClose, plant }: ManagePlantProps): JSX.Element {
  const queryClient = useQueryClient();

  const formToPlant = (data: FormData): PlantModel => {
    console.log("data", data)
    const plant: PlantModel = {
      _id: "",
      name: data.name,
      description: data.description ? data.description : "",
      tags: data?.tags.length > 0 ? data.tags : [],
      daysToHarvest: data.daysToHarvest ? data.daysToHarvest : 0,
      url: data.url ? data.url : "",
      image: data.image ? data.image : "",
    };
    console.log("plant", plant)
    return plant;
  };

  const addPlant = useMutation({
    mutationFn: (data: FormData) => {
      return NewPlant(formToPlant(data));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["plant"] });
    },
  });

  const updatePlant = useMutation({
    mutationFn: (data: FormData) => {
      const body = formToPlant(data);
      return EditPlant(plant._id, body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["plant"] });
    },
  });

  const handleCancel = () => {
    handleClose();
  };

  const handleAddPlant = (data: FormData) => {
    addPlant.mutate(data);
    handleClose();
  };

  const handleEditPlant = (data: FormData) => {
    updatePlant.mutate(data);
    handleClose();
  };

  const handleUpdatePlant = (data: FormData) => {
    if (!plant._id) {
      handleAddPlant(data);
    } else {
      handleEditPlant(data);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Plant name is required"),
    description: Yup.string(),
    daysToHarvest: Yup.number().nullable().min(0, "Days to Harvest must be a positive number").integer("Days to Harvest must be an integer"),
    url: Yup.string().url("URL must be a valid URL"),
    image: Yup.string(),
    tags: Yup.array().of(Yup.string().required("Tag can not be empty")).required(),
  });

  interface FormData extends Yup.InferType<typeof validationSchema> {}

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver<FormData>(validationSchema), defaultValues: plant });

  return (
    <Card>
      <CardContent>
        <TextField id="name" label="Name" variant="filled" fullWidth={true} type="text" {...register("name")} error={errors.name ? true : false} />
        <Typography variant="inherit" color="textSecondary">
          {errors.name?.message}
        </Typography>
        <TextField
          id="description"
          label="Description"
          variant="filled"
          fullWidth={true}
          type="text"
          multiline={true}
          rows="4"
          {...register("description")}
          error={errors.description ? true : false}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors.description?.message}
        </Typography>
        <TextField id="daysToHarvest" label="Days to Harvest" variant="filled" fullWidth={true} type="number" {...register("daysToHarvest")} error={errors.daysToHarvest ? true : false} />
        <Typography variant="inherit" color="textSecondary">
          {errors.daysToHarvest ? "Days to Harvest must be a positive number and an integer" : ""}
        </Typography>
        <TextField id="url" label="URL" variant="filled" fullWidth={true} type="text" {...register("url")} error={errors.url ? true : false} />
        <Typography variant="inherit" color="textSecondary">
          {errors.url?.message}
        </Typography>

        <VegiImage
          name="image"
          value={plant.image}
          control={control}
          setValue={setValue}
        />
        <EditTags
          name="tags"
          value={plant.tags}
          setValue={setValue}
          control={control}
        />
      </CardContent>
      <CardActions>
        <Button variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleUpdatePlant)}>Update</Button>
      </CardActions>
    </Card>
  );
}
