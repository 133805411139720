import { Box, Button, Card, CardContent, CardHeader, CardMedia, Paper } from "@mui/material";
import { GardenModel } from "../../data/Model";
import { getGardenData } from "../../data/Garden";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import styles from "../../theme/GardenTheme.module.scss";
import AddPlotHistory from "../MangePlotHistory/MangePlotHistory";

interface PlotHistoryProps {
  gardenId: string;
  plotId: string;
}

export default function PlotHistory({ gardenId, plotId }: PlotHistoryProps): JSX.Element {
  const gardenQuery = useQuery({ queryKey: ["garden", gardenId], queryFn: () => getGardenData(gardenId) });
  const garden = gardenQuery.data?.data as GardenModel;

  const [showAddHistory, setShowAddHistory] = React.useState<boolean>(true);

  const handleAddHistoryClicked = () => {
    setShowAddHistory(false);
  };
  const handleCancelHistory = () => {
    setShowAddHistory(true);
  };

  const plot = garden.plots.filter((p) => p._id === plotId)[0];
  return (
    <Paper>
      {showAddHistory ? (
        <Button variant="contained" onClick={handleAddHistoryClicked}>Add History</Button>
      ) : (
        <AddPlotHistory  handleClose={handleCancelHistory} plotId={plotId} history={{ _id: "", description: "", date: "" , image: "", thumbnail: "" }} />
      )}
      {plot.history.map((item) => {
        return (
          <Card variant="outlined" key={item._id}>
            <CardHeader title={item.date} />
            <Box>
              {item.image && <CardMedia className={styles.plantimage} component="img" image={`https://assets.vegiweb.frew.dev/${item.image}`} />}
              <CardContent>{item.description}</CardContent>
            </Box>
          </Card>
        );
      })}
    </Paper>
  );
}
