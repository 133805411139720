import { jsx } from "@emotion/react";
import JSX = jsx.JSX;
import { deleteGarden, getAllGardenData } from "../../data/Garden";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GardenModel } from "../../data/Model";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ManageGarden from "../ManageGarden/ManageGarden";
import EditNoteIcon from "@mui/icons-material/EditNote";

export default function Garden(): JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data } = useQuery({ queryKey: ["garden"], queryFn: getAllGardenData });
  const deleteGardenMutation = useMutation({
    mutationFn: (id: string) => {
      return deleteGarden(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden"] });
    },
  });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
  const [gardenToDelete, setGardenToDelete] = React.useState<string>("");
  const [showNewGarden, setShowNewGarden] = React.useState<boolean>(true);
  const [editGardenId, setEditGardenId] = React.useState<string>("");

  const handleCloseAddGarden = () => {
    setShowNewGarden(true);
  };

  const handleCloseEditGarden = () => {
    setEditGardenId("");
  };

  const handleSelectGarden = (gardenId: string) => {
    navigate(`/garden/${gardenId}`);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };
  const handleConfirmDelete = () => {
    deleteGardenMutation.mutate(gardenToDelete);
    setIsDeleteDialogOpen(false);
  };
  const handleDeleteGarden = (gardenId: string) => {
    setGardenToDelete(gardenId);
    setIsDeleteDialogOpen(true);
  };

  const handleShowNewGarden = () => {
    setShowNewGarden(false);
  };

  const handleEditGarden = (gardenId:string) => {
    setEditGardenId(gardenId);
  };

  const gardens = data?.data as GardenModel[];

  return (
    <>
      <List>
        <ListItem divider key="add-garden">
          {showNewGarden ? (
            <Button onClick={handleShowNewGarden} variant="contained">
              Add Garden
            </Button>
          ) : (
            <ManageGarden handleClose={handleCloseAddGarden} garden={{_id:'',name:'',plots:[], owner:[]}} />
          )}
        </ListItem>
        {gardens?.map((garden) => {
          return (
            <ListItem divider key={garden._id}>
              {editGardenId === garden._id ? (
                <ManageGarden handleClose={handleCloseEditGarden} garden={garden} />
              ) : (
                <>
                  <ListItemButton onClick={() => handleSelectGarden(garden._id)}>
                    <ListItemText primary={garden.name} />
                  </ListItemButton>
                  <IconButton aria-label="edit" onClick={() => handleEditGarden(garden._id)}>
                    <EditNoteIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => handleDeleteGarden(garden._id)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </>
              )}
            </ListItem>
          );
        })}
      </List>
      <Dialog open={isDeleteDialogOpen} fullWidth={true} maxWidth="md">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>You can not undo this action</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant="outlined">Cancel</Button>
          <Button variant="contained" color="error" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
