import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Header from "../components/Header/Header";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Garden from "../components/Garden/Garden";
import Login from "../components/Login/Login";
import { useAuth } from "../hooks/useAuth";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";
import Plots from "../components/Plots/Plots";
import Plants from "../components/Plants/Plants";

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log("mutation error", error);
      toast.error("Error: " + error.message);
    },
  }),
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.log("query error", error, query);
      toast.error("Error: " + error.message);
    },

  })
});

export default function MainPage(): JSX.Element {
  const auth = useAuth();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Garden />,
      loader: async () => {
        return {
          gardenId: "1",
        };
      },
    },
    {
      path: "/garden/:gardenId",
      element: <Plots />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    { 
      path: "/plant",
      element: <Plants/>
    }
  ]);

  const handleNavigation = (location: string) => {
    router.navigate(location);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Toaster></Toaster>
        <Header handleNavigation={handleNavigation} />
        <Container>{auth.isAuthenticated ? <RouterProvider router={router} /> : <Login />}</Container>
      </QueryClientProvider>
    </React.Fragment>
  );
}
