import { Chip, FormControl, IconButton, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Controller } from "react-hook-form";

interface EditTagsProps {
  name: string;
  value: string[];
  control: any;
  setValue: any;
}

export function EditTags({ 
  name, 
  value,
  control, 
  setValue, 
}: EditTagsProps): JSX.Element {
  const [tags, setTags] = React.useState<string[]>(value?value:[]);
  const [showNewTag, setShowNewTag] = React.useState<boolean>(false);
  const [newTag, setNewTag] = React.useState<string>("");

  useEffect(()=>{
    setValue(name, tags);
  }, [tags,setValue,name])

  const handleDelete = (tag: string) => () => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
  }

  const handleAddTag = () => {
    setShowNewTag(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewTag(e.currentTarget.value);
  };

  const handleTagInput = () => {
    const updatedTagss = newTag.split(",").map((tag) => { tag.trim(); return tag }).filter((tag) => { return (tag && !tags.includes(tag))? true : false } )
    setTags([...tags, ...updatedTagss]);
    handleHide();
  };

  const handleHide = () => {
    setNewTag("");
    setShowNewTag(false);
  };

  return (
    <FormControl>
      <Paper sx={{ display: "flex", justifyContent: "flex-start", alignContent: "flex-start", listStyle: "none", p: "2px" }}>
        <Typography variant="h6" sx={{ paddingRight: "10px" }}>
          Tags:
        </Typography>
          <IconButton size="small" color="primary" onClick={handleAddTag}>
            <AddCircleIcon />
          </IconButton>
        {showNewTag && (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={handleOnChange}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton onClick={handleTagInput} color="success">
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton color="warning" onClick={handleHide}>
                        <NotInterestedIcon />
                      </IconButton>
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        {tags.map((tag) => {
          return ( <Chip key={tag} variant="outlined" label={tag} onDelete={handleDelete(tag)} color="primary" />);
        })}
      </Paper>
    </FormControl>
  );
}
