import { AppBar, Button, Divider, IconButton, LinearProgress,  Menu, MenuItem, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../hooks/useAuth";
import HomeIcon from "@mui/icons-material/Home";
import React from "react";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

interface HeaderProps {
  handleNavigation: (location: string) => void;
}

export default function Header({ handleNavigation }: HeaderProps): JSX.Element {
  const auth = useAuth();

  const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

  const handleLogin = () => {
    if (auth.isAuthenticated) {
      auth.signOut();
    } else {
      auth.signIn();
    }
  };

  const handleGoHome = () => {
    handleNavigation("/");
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const handlePlants= () => {
    handleNavigation(`/plant`);
  };

  const handleGardens = () => {
    handleNavigation(`/`);
  };

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const showLoading = isFetching > 0 || isMutating > 0;

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
        {auth.isAuthenticated && (
          <Menu anchorEl={menuAnchorElement} open={menuAnchorElement !== null} onClose={handleMenuClose}>
            <MenuItem onClick={handleGardens} >Gardens</MenuItem>
            <Divider key="div" />
            <MenuItem onClick={handlePlants}>Plants</MenuItem>
          </Menu>
        )}
        <IconButton onClick={handleGoHome}>
          <HomeIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
          Vegi Garden
        </Typography>
        <Typography>{auth.isAuthenticated ? auth.username : "Not Logged In"}</Typography>
        <Button color="inherit" onClick={handleLogin}>
          {auth.isAuthenticated ? "Sign Out" : "Sign In"}
        </Button>
      </Toolbar>
      {showLoading && <LinearProgress />}
    </AppBar>
  );
}
