import { Amplify, Auth} from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";

//TODO: these should come from envs
const AmplifyConfig = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_PuIK81rDQ",
    userPoolWebClientId: "6fnrt4t2vtcidj0nspm13359al",
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: "vegiweb.auth.ap-southeast-2.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      cliendId: "6fnrt4t2vtcidj0nspm13359al",
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "vegiweb",
        endpoint: "https://api.vegiweb.frew.dev",
        customHeader: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
};

Amplify.configure(AmplifyConfig);

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  signIn: () => Promise<Result>;
  signOut: () => void;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    Auth.currentUserInfo()
      .then((result) => {
        setUsername(result.attributes.email);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername("");
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signIn = async () => {
    return await Auth.federatedSignIn()
      .then((result) => {
        return Auth.currentUserInfo().then((user) => {
          console.log("currentUser",JSON.stringify(user))
          setUsername(user.attributes.email);
          setIsAuthenticated(true);
          return { success: true, message: "" };
        });
      })
      .catch((error) => {
        return {
          success: false,
          message: "LOGIN FAIL",
        };
      });
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername("");
      setIsAuthenticated(false);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGOUT FAIL",
      };
    }
  };

  
  return {
    isLoading,
    isAuthenticated,
    username,
    signIn,
    signOut,
  };
};
