import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Accordion, AccordionDetails, AccordionSummary, Typography, AccordionActions, Avatar } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { GardenModel} from "../../data/Model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getGardenData } from "../../data/Garden";
import { DeletePlot } from "../../data/Plot";
import styles from "../../theme/GardenTheme.module.scss";
import PlotHistory from "../PlotHistory/PlotHistory";
import ManagePlot from "../ManagePlot/ManagePlot";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Tags } from "../Tags/Tags";

export default function Plots(): JSX.Element {
  const params = useParams();
  const queryClient = useQueryClient();

  const gardenId = params.gardenId ? params.gardenId : "";
  const gardenQuery = useQuery({ queryKey: ["garden", gardenId], queryFn: () => getGardenData(gardenId) });

  const deletePlotMutation = useMutation({
    mutationFn: (plotId: string) => {
      return DeletePlot(gardenId, plotId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden", gardenId] });
    },
  });

  const garden = gardenQuery.data?.data as GardenModel;

  const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
  const [plotToDelete, setPlotToDelete] = React.useState<string>("");
  const [expandedPlotId, setExpandedPlotId] = React.useState<string | false>(false);
  const [showAddPlot, setShowAddPlot] = React.useState<boolean>(true);
  const [editPlotId, setEditPlotId] = React.useState<string>("");

  const handleDeletePlot = (id: string) => {
    setPlotToDelete(id);
    setIsConfirmOpen(true);
  };

  const handleEditPlot = (plotId: string) => {
    setEditPlotId(plotId);
  };

  const handleCancelDelete = () => {
    setPlotToDelete("");
    setIsConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    deletePlotMutation.mutate(plotToDelete);
    setPlotToDelete("");
    setIsConfirmOpen(false);
  };

  const handleAddPlantButton = () => {
    setShowAddPlot(false);
  };
  const handleCloseAddPlot = () => {
    setShowAddPlot(true);
  };

  const handleCloseEditPlot = () => {
    setEditPlotId("");
  };

  const handlePlotChange = (panelId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPlotId(isExpanded ? panelId : false);
  };

  const tags = [ "a", "b", "c"]
  return (
    <Paper elevation={3}>
      <Typography variant="h2">{garden?.name}</Typography>
      {showAddPlot ? (
        <Button onClick={handleAddPlantButton} variant="contained" className={styles.button}>
          Add Plot
        </Button>
      ) : (
        <ManagePlot handleClose={handleCloseAddPlot} plot={{ _id: "", name: "", description: "", history: [], plantId:"" }} />
      )}
      {garden?.plots?.map((plot) => {
        return (
          <Accordion key={plot._id} expanded={expandedPlotId === plot._id} onChange={handlePlotChange(plot._id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              {editPlotId === plot._id ? (
                <ManagePlot handleClose={handleCloseEditPlot} plot={plot} />
              ) : (
                <>
                  <Avatar alt={plot.name} src={`https://assets.vegiweb.frew.dev/`} />
                  <div className={styles.mixedtitle}>
                    <Typography variant="h6">{plot.name}</Typography>
                  </div>
                  <Typography variant="body2" width="100%">
                    {plot.description}
                  </Typography>
                  <AccordionActions>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditPlot(plot._id);
                      }}
                    >
                      <EditNoteIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePlot(plot._id);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </AccordionActions>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Tags value={tags} />
              <PlotHistory gardenId={gardenId} plotId={plot._id} />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Dialog open={isConfirmOpen} fullWidth={true} maxWidth="md">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>You can not undo this action</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant="outlined">Cancel</Button>
          <Button color="error" onClick={handleConfirmDelete} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
