import TextField from "@mui/material/TextField";
import VegiImage from "../VegiImage/VegiImage";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import styles from "../../theme/GardenTheme.module.scss";
import { Card, CardActions, CardContent } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PlotHistoryModel } from "../../data/Model";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NewPlotHistory } from "../../data/Plot";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-nz";
import * as Yup from "yup";
import dayjs from "dayjs";

interface ManagePlotProps {
  handleClose: () => void;
  plotId: string;
  history: PlotHistoryModel;
}

export default function ManagePlotHistory({ handleClose, plotId, history }: ManagePlotProps): JSX.Element {
  const params = useParams();
  const queryClient = useQueryClient();

  const gardenId = params.gardenId ? params.gardenId : "";

  const formToHistory = (data: FormData): PlotHistoryModel => {
    return {
      _id: "",
      description: data.description,
      image: data.image,
      thumbnail: "",
      date: data.date.toISOString(),
    };
  };

  const addHistory = useMutation({
    mutationFn: (data: FormData) => {
      return NewPlotHistory(gardenId, plotId, formToHistory(data));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden", gardenId] });
    },
  });
  const updateHistory = useMutation({
    mutationFn: (data: FormData) => {
      return NewPlotHistory(gardenId, plotId, formToHistory(data));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden", gardenId] });
    },
  });

  const handleSavePlotHistory = (data: FormData) => {
    if (history._id) {
      updateHistory.mutate(data);
    } else {
      addHistory.mutate(data);
    }
    handleClose();
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    image: Yup.string().required("Image is required"),
    date: Yup.date().required("History date is required"),
  });

  interface FormData extends Yup.InferType<typeof validationSchema> {}
  const {
    register,
    handleSubmit,
    setValue,
    control,
  } = useForm<FormData>({ resolver: yupResolver<FormData>(validationSchema), defaultValues: { description: "", image: "", date: new Date() } });


  return (
    <Card>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-nz">
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Date note taken"
                {...field}
                value={dayjs(field.value)}
                onChange={(newValue) => {
                  if (newValue) {
                    setValue("date", newValue.toDate());
                  }
                }}
              />
            )}
          />
        </LocalizationProvider>
        <TextField {...register("description")} className={styles.input} label={"Plot History Description"} variant="filled" type="text" fullWidth={true} multiline={true} rows="4" />
        <VegiImage name="image" control={control} value="https://s3.ap-southeast-2.amazonaws.com/dev.frew.static/herbs-bundle-grey.svg" setValue={setValue} />
      </CardContent>
      <CardActions>
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
        <Button onClick={handleSubmit(handleSavePlotHistory)} variant="contained" >Save</Button>
      </CardActions>
    </Card>
  );
}
