import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import styles from "../../theme/GardenTheme.module.scss";
import { Autocomplete, Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PlantModel, PlotModel } from "../../data/Model";
import { EditPlot, NewPlot } from "../../data/Plot";
import { GetPlants } from "../../data/Plants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {  useForm } from "react-hook-form";

interface AddPlotProps {
  handleClose: () => void;
  plot: PlotModel;
}

export default function MangePlot({ handleClose, plot }: AddPlotProps): JSX.Element {
  const params = useParams();
  const queryClient = useQueryClient();

  const plantQuery = useQuery({ queryKey: ["plant"], queryFn: () => GetPlants() });
  const plants = plantQuery.data?.data ? (plantQuery.data.data as PlantModel[]) : ([] as PlantModel[]);

  const gardenId = params.gardenId ? params.gardenId : "";
  const addPlot = useMutation({
    mutationFn: (data: FormData) => {
      const newPlot = formToPlot(data);
      return NewPlot(gardenId, newPlot);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden", gardenId] });
    },
  });

  const updatePlot = useMutation({
    mutationFn: (data: FormData) => {
      const newPlot = formToPlot(data);

      return EditPlot(gardenId, newPlot);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["garden", gardenId] });
    },
  });

  const formToPlot = (data: FormData): PlotModel => {
    const newPlot: PlotModel = {
      _id: plot._id,
      name: data.name,
      description: data.description ? data.description : "",
      history: [],
      plantId: data.plantId ? data.plantId : "",
    };

    return newPlot;
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleAddPlot = (data: FormData) => {
    addPlot.mutate(data);
    handleClose();
  };

  const handleEditPlot = (data: FormData) => {
    updatePlot.mutate(data);
    handleClose();
  };

  const handleUpdatePlot = (data: FormData) => {
    console.log("form data", data);
    if (plot._id === "") {
      handleAddPlot(data);
    } else {
      handleEditPlot(data);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Plant name is required"),
    description: Yup.string(),
    plantId: Yup.string(),
    history: Yup.array(),
  });

  interface FormData extends Yup.InferType<typeof validationSchema> {}

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver<FormData>(validationSchema), defaultValues: plot });

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <TextField className={styles.input} label={"Plot Name"} variant="filled" type="text" fullWidth={true} {...register("name")} error={errors.name ? true : false} />

        <Typography variant="inherit" color="textSecondary">
          {errors.name?.message}
        </Typography>
        <TextField
          className={styles.input}
          label={"Plot Description"}
          variant="filled"
          fullWidth={true}
          multiline={true}
          rows="4"
          {...register("description")}
          error={errors.description ? true : false}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors.description?.message}
        </Typography>
        <Autocomplete
          {...register("plantId")}
          fullWidth={true}
          defaultValue={plants.filter(p => p._id === plot.plantId)[0]}
          options={plants}
          autoHighlight={true}
          onChange={(e, value) => {setValue("plantId", value?._id)}}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            return (
              <Box component="li" sx={{ width: "100%", "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <img loading="lazy" width="20" src={`https://assets.vegiweb.frew.dev/${option._id}.webp`} alt="" />
                {option.name}
              </Box>
            );
          }}
          renderInput={(params) => <TextField {...params} label="Plant" variant="filled" fullWidth={true} />}
        />
      </CardContent>
      <CardActions>
        <Button variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleUpdatePlot)}>Update</Button>
      </CardActions>
    </Card>
  );
}
